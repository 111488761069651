<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'

export default {
  // mixins: [AppDeviceEnquire],
  data () {
    return {
      locale: zhCN
    }
  },
  mounted () {
    const mask = document.getElementById('loading-mask')
    setTimeout(() => {
      mask.parentNode.removeChild(mask)
    }, 100)
  }
}
</script>
<style>
  #app {
    height: 100%;
  }
</style>
