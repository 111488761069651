import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import router from './router'
import store from './store'
import 'es6-promise/auto'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import './core/lazy_use'
import VueQriously from 'vue-qriously'
import VueCookies from 'vue-cookies'
import mountUtil from '@/utils/mountUtil'
// 全局公共样式
import './style/hanweb.css'
import './style/commonStyle.less'
import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'
import VueDOMPurifyHTML from 'vue-dompurify-html'
NProgress.configure({ ease: 'ease', speed: 500 })

router.beforeEach((to, from, next) => {
  // token验证，如果存储在sessionStorage里的auth的值丢失，就回到登陆页面，(开发时可以注释掉)
  // if (!sessionStorage.getItem('auth')) {
  //   return next(`${base}login`)
  // }

  // 如果页面在 /  默认页面，跳转到登陆页面(和vue路由重定向功能类似)
  // if (to.path === `/`) {
  //   return next(`/login`)
  // }
  // NProgress开始进度条
  NProgress.start()
  if (to.meta.title === '阅读') {
    // 判断是否有标题
    document.title = to.query.name
  } else {
    document.title = to.meta.title
  }
  next()
})

// 全局后置钩子-常用于结束动画等
router.afterEach(transition => {
  // NProgress结束进度条
  NProgress.done()
})

Vue.use(VueRouter)
Vue.use(mountUtil)
Vue.use(VueCookies)
Vue.use(VueQriously)
Vue.use(VueDPlayer)
Vue.use(VueDOMPurifyHTML)
Vue.config.productionTip = false
Vue.prototype.$BASE_URL = process.env.BASE_URL
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
