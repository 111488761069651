<template>
  <header class="wkl-mainHeader" ref="wkl_mainHeader">
    <!-- 最顶部 -->
    <div class="wkl-mainHeader-top">
      <div class="wkl-mainHeader-top__container">
        <template v-if="isLogin">
          <!-- 已登录 -->
          <ul class="wkl-mainHeader-topNav">
            <router-link class="wkl-mainHeader-topNav__item" to="/MyBookshelf" tag="li">我的书架</router-link>
            <router-link class="wkl-mainHeader-topNav__item" to="/MyOrder" tag="li">我的订单</router-link>
            <template v-if="false">
              <li class="wkl-mainHeader-topNav__item">移动端</li>
            </template>
          </ul>
          <router-link class="wkl-mainHeader-userInfo" to="/personal-center" tag="div">
            <img class="wkl-mainHeader-userInfo__avatar" src="@/assets/myvip/userimg.png" alt="头像" />
            <span class="wkl-mainHeader-userInfo__nickname">{{ userDetail.mobile }}</span>
          </router-link>
          <div class="wkl-mainHeader-logout" @click="clearLoginState">退出</div>
        </template>
        <template v-else>
          <!-- 未登录 -->
          <ul class="wkl-mainHeader-topNav">
            <li class="wkl-mainHeader-topNav__item">
              欢迎访问世知书屋！请
              <router-link class="wkl-mainHeader-topNav__link" to="/Login">登录</router-link>
              或
              <router-link class="wkl-mainHeader-topNav__link" to="/Register">注册</router-link>成为会员
            </li>
            <template v-if="false">
              <li class="wkl-mainHeader-topNav__item">移动端</li>
            </template>
          </ul>
        </template>

      </div>
    </div>
    <!-- logo和检索-->
    <div class="wkl-mainHeader-logoSearch">
      <div class="wkl-mainHeader-logoSearch__container">
        <!-- logo -->
        <router-link class="wkl-mainHeader-logo" to="/" tag="div">
          <img class="wkl-mainHeader-logo__img" src="@/assets/mainHeader/headerLogo.png" alt="logo" />
          <img class="wkl-mainHeader-logo__text" src="@/assets/mainHeader/headerLogoText.png" alt="logoText" />
        </router-link>
        <div class="seacrch_content">
          <!-- 检索 -->
          <div class="wkl-mainHeader-search">
            <a-select
              class="wkl-mainHeader-search_select"
              :value="searchType"
              :getPopupContainer="getPopupContainer"
              @change="dateChange">
              <a-select-option value="1"> 图书 </a-select-option>
              <a-select-option value="2"> 书单 </a-select-option>
              <a-select-option value="3"> 期刊 </a-select-option>
              <a-select-option value="4"> 视频 </a-select-option>
              <a-select-option value="5"> 音频 </a-select-option>
              <a-select-option value="6"> 书评 </a-select-option>
            </a-select>
            <input
              @keyup.enter="searchResult"
              @focus="searchFocus"
              @blur="searchBlur"
              class="wkl-mainHeader-search__input"
              type="text"
              placeholder="请输入关键字"
              v-model="defaultValue"
              :maxlength="32"
              ref="head_search" />
            <div class="wkl-mainHeader-searchSubmit" @click="searchResult">
              <img
                class="wkl-mainHeader-searchSubmit__icon"
                src="@/assets/mainHeader/headerSearchIcon.png"
                alt="searchIcon" />
              <span class="wkl-mainHeader-searchSubmit__text">检索</span>
            </div>
          </div>
          <!-- 热词 -->
          <ul class="searchWord">
            <li>搜索热词：</li>
            <li v-for="(hotword, index) in hotwords" :key="index" @click="search(hotword)" :title="hotword">{{ hotword }}</li>
            <template v-if="false">
              <li class="change">换一换</li>
            </template>
          </ul>
          <!-- 历史搜索 -->
          <ul v-show="historyWordShow" class="historyWords">
            <li>最近搜索</li>
            <li v-for="(historyWord, index) in historyWords" :key="index">
              <span @click="search(historyWord)" @mousedown="preventBlur($event)">{{ historyWord }}</span><span
                @click="removeHistoryWord(historyWord)"
                @mousedown="preventBlur($event)">删除</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 导航 -->
    <div class="wkl-mainHeader-nav">
      <div class="wkl-mainHeader-nav__container">
        <ul class="wkl-mainHeader-navList">
          <router-link
            class="wkl-mainHeader-navItem"
            v-for="(item, index) in navList"
            :class="{ 'wkl-mainHeader-navItem--active': item.path === currentRouter.path }"
            :to="item.path"
            tag="li"
            :key="index">
            <span class="wkl-mainHeader-navItem__text">{{ item.name }}</span>
          </router-link>
          <li class="wkl-mainHeader-navItem" @click="toShop">
            <span class="wkl-mainHeader-navItem__text">商城</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 左侧定位 -->
    <ul class="wkl-mainHeader-rigthflex" v-if="isLogin">
      <router-link to="/personal-center" tag="li" title="个人中心" class="personal_center"></router-link>
      <li class="phone">
        <img src="@/assets/mainHeader/ewm.png" alt="">
      </li>
      <li title="在线客服" class="WeChat" @click="WeChat()">
      </li>
      <li class="wkl-translateIcon" @click="showTranslateModal" title="点击进行翻译"></li>
      <a-badge :count="shoppingCartTotal" :show-zero="shoppingCartTotal !== 0">
        <router-link to="ShoppingCarIndex" tag="li" title="进入购物车" class="shopping_car"></router-link>
      </a-badge>
      <li class="toping" @click="toping" title="置顶"></li>
    </ul>

    <!-- 翻译功能 -->
    <wkl-translate :show="translateShow" @close="closeTranslateModal" />

  </header>
</template>
<!-- 通用头部 -->
<script>
import { requestInterface } from '@/api/wklAPI'
import wklTranslate from '@components/wklTranslate.vue'
import { searchRegMobile } from '@/utils/util'
import { getTicket } from '@/utils/SSOLogin'

export default {
  name: 'MainHeader',
  components: {
    wklTranslate
  },
  props: {
    currentRouter: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: ''
        }
      }
    }
  },
  computed: {
    isLogin () {
      return this.$util.isLogin()
    }
  },
  data () {
    return {
      searchBg: true,
      defaultValue: '',
      // 检索类别
      searchType: '1',
      // 购物车数量
      shoppingCartTotal: 0,
      // 购物车数量改变
      shoppingCartTotalChange: true,
      // 搜索热词
      hotwords: [],
      // 历史搜索
      historyWords: [],
      // 历史搜索显示隐藏
      historyWordShow: false,
      navList: [
        { name: '首页', path: '/' },
        { name: '书单广场', path: '/BookListPlaza' },
        { name: '期刊专区', path: '/journal-zone' },
        { name: '排行榜', path: '/ranking' },
        { name: '视频专区', path: '/video-zone' },
        { name: '音频专区', path: '/audio-zone' },
        { name: '书评专区', path: '/book-review-zone' }
      ],
      // 用户信息
      userDetail: {},
      /**
       * 翻译相关
       */
      translateShow: false
    }
  },
  mounted () {
    this.getShoppingCartTotal()
    this.$bus.$on('shoppingCartTotalChange', (data) => {
      this.shoppingCartTotalChange = data
    })
    this.$bus.$on('searchKeyWord', (data) => {
      this.defaultValue = data
    })
    this.$bus.$on('searchType', (data) => {
      this.searchType = data
    })

    if (this.$route.query.searchType > 0) {
      this.defaultValue = this.$route.query.searchValue
      this.searchType = this.$route.query.searchType
    }
    this.hotWordfindHotWords(this.searchType)
    // 获取用户信息
    if (this.isLogin) {
      this.getUserInfo().then(() => {
        // 如果本地存储的数据historyWords有值，直接赋值给data中的historyWords
        if (JSON.parse(decodeURIComponent(this.$cookies.get('historyWords-' + this.userDetail.uid)))) {
          this.historyWords = JSON.parse(decodeURIComponent(this.$cookies.get('historyWords-' + this.userDetail.uid))).slice(0, 7)
          this.$cookies.set('historyWords-' + this.userDetail.uid, encodeURIComponent(JSON.stringify(this.historyWords)), 604800)
        }
      })
    }
  },
  methods: {
    /**
     * 跳转至商城
     */
    toShop() {
      const urlList = ['htt', 'ps://', 'shop.ishizhi', '.cn/pc/#/']
      getTicket().then(ticket => {
        let url = ''
        for (const item of urlList) {
          url += item
        }
        window.open(`${url}?ticket=${ticket}`, '_blank')
      })
    },
    // 跳转检索页或检索结果页
    search (value) {
      if (searchRegMobile(value)) {
        return this.$message.info('请勿输入手机号敏感词')
      }
      if (this.searchType === '1' || this.searchType === '2' || this.searchType === '3') {
        this.$router.push(`/SearchResult?searchValue=${value}&searchType=${this.searchType}`)
      }
      if (this.searchType === '4' || this.searchType === '5' || this.searchType === '6') {
        this.$router.push(`/CMSSearch?searchValue=${value}&searchType=${this.searchType}`)
      }
      this.defaultValue = value
      this.$refs.head_search.blur()
    },
    /**
     * 检索前的校验
     */
    VerificationBeforeSearch() {
      if (searchRegMobile(this.defaultValue)) {
        this.$message.info('请勿输入手机号敏感词')
        return false
      }
      if (this.defaultValue === '' || this.defaultValue === undefined) {
        this.$message.error('请输入关键字!')
        return false
      }
      return true
    },
    searchResult () {
      if (!this.VerificationBeforeSearch()) return
      // 没有搜索记录，把搜索值push进数组首位，存入本地
      if (!this.historyWords.includes(this.defaultValue)) {
        this.historyWords.unshift(this.defaultValue)
        this.$cookies.set('historyWords-' + this.userDetail.uid, encodeURIComponent(JSON.stringify(this.historyWords)), 604800)
        const cookiesData = []
        this.$cookies.keys().forEach(item => {
          if (item.indexOf('historyWords-') === 0) {
            cookiesData.unshift(item)
          }
        })
        if (cookiesData.length > 5) {
          this.$cookies.remove(cookiesData[5])
        }
      } else {
        // 有搜索记录，删除之前的旧记录，将新搜索值重新push到数组首位
        const i = this.historyWords.indexOf(this.defaultValue)
        this.historyWords.splice(i, 1)
        this.historyWords.unshift(this.defaultValue)
        this.$cookies.set('historyWords-' + this.userDetail.uid, encodeURIComponent(JSON.stringify(this.historyWords)), 604800)
      }

      this.toSearchPage()
    },
    /**
     * 前往搜索页面
     */
    toSearchPage() {
      let url = ''
      if (['1', '2', '3'].includes(this.searchType)) {
        url = `/SearchResult?searchValue=${this.defaultValue}&searchType=${this.searchType}`
      } else if (this.searchType === '4') {
        url = `/search-video?searchValue=${this.defaultValue}`
      } else if (this.searchType === '5') {
        url = `/search-audio?searchValue=${this.defaultValue}`
      } else if (this.searchType === '6') {
        url = `/CMSSearch?searchValue=${this.defaultValue}&searchType=${this.searchType}`
      }

      this.$router.push(url)
    },
    getPopupContainer () {
      return this.$refs.wkl_mainHeader
    },
    dateChange (value) {
      this.hotWordfindHotWords(value)
      this.searchType = value
      this.$bus.$emit('searchType', value)
    },
    // 清除登录状态
    clearLoginState () {
      this.$cookies.remove('access-token')
      sessionStorage.removeItem('userdetail')
      location.reload()
    },
    // 获取购物车数量总数
    getShoppingCartTotal () {
      if (!this.isLogin) return
      requestInterface({
        interfaceId: 'electronBookCartfindCartCount'
      }).then((res) => {
        this.shoppingCartTotal = res.data.count
        this.shoppingCartTotalChange = true
      })
    },
    // 获取热词列表
    hotWordfindHotWords (val) {
      if (val === '2') {
        val = '5'
      } else if (val === '3') {
        val = '2'
      } else if (val === '4') {
        val = '3'
      } else if (val === '5') {
        val = '4'
      }
      requestInterface({
        interfaceId: 'hotWordfindHotWord',
        header: {
          'access-token': ''
        },
        params: {
          count: '5',
          classifyId: val
        }
      }).then((res) => {
        if (!res.success) return
        this.hotwords = []
        const data = res.data.resultData
        data.forEach((item) => {
          this.hotwords.push(item.hotWordName)
        })
        this.hotwords = this.hotwords.slice(0, 5)
      })
    },
    // 删除历史记录
    removeHistoryWord (val) {
      this.historyWordShow = true
      this.historyWords = JSON.parse(decodeURIComponent(this.$cookies.get('historyWords-' + this.userDetail.uid)))
      this.historyWords = this.historyWords.filter((o) => {
        return o !== val
      })
      this.$cookies.set('historyWords-' + this.userDetail.uid, encodeURIComponent(JSON.stringify(this.historyWords)), 604800)
    },
    // 获取焦点
    searchFocus () {
      if (this.historyWords.length > 0) {
        this.historyWordShow = true
      }
    },
    // 失去焦点
    searchBlur () {
      this.historyWordShow = false
    },
    // 阻止失去焦点
    preventBlur (event) {
      event.preventDefault()
    },
    // 返回至顶部
    toping () {
      document.documentElement.scrollTop = 0
    },
    // 获取用户基本信息
    getUserInfo () {
      return requestInterface({
        interfaceId: 'useruserInfo'
      }).then(res => {
        if (!res.success) return
        this.userDetail = res.data.resultData
        sessionStorage.setItem('userdetail', JSON.stringify(res.data.resultData))
      })
    },
    /**
     * 显示翻译对话框
     */
    showTranslateModal () {
      this.translateShow = true
    },
    /**
     * 关闭翻译对话框
     */
    closeTranslateModal () {
      this.translateShow = false
    },
    /**
     * 跳转微信客服
     */
    WeChat () {
      window.open('https://work.weixin.qq.com/kfid/kfce400445f40f1502c', '_blank', 'rel=noopener')
    }
  },

  watch: {
    shoppingCartTotalChange () {
      this.getShoppingCartTotal()
    },
    searchType () {
      this.hotWordfindHotWords(this.searchType)
    }
  }
}
</script>
<style lang="less" scoped>
@import "@/style/commonStyle.less";

.wkl-mainHeader {
  width: 100%;

  // 最顶部
  &-top {
    display: flex;
    align-items: center;
    width: 100%;
    background: radial-gradient(50.00% 50.00% at 50.00% 50.00%, #fff 0%, #fff9f9 57.73%, #fff5f5 100%);

    &__container {
      .container();
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 40px;
    }

    &Nav {
      display: flex;

      &__item {
        .defaultText();
        cursor: pointer;

        &:before {
          content: "";
          height: 14px;
          border-right: 1px solid #DBDBDB;
          margin: 0 10px;
        }

        &:first-child:before {
          content: unset;
        }
        // &:last-child {
        //   cursor: default;
        //   color: #999;
        // }
      }

      &__link {
        .defaultText(#CC0000);
      }
    }
  }

  // 用户信息
  &-userInfo {
    display: flex;
    align-items: center;
    margin-left: 18px;
    cursor: pointer;

    // 头像
    &__avatar {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }

    // 昵称
    &__nickname {
      .defaultText();
    }

  }

  // 退出
  &-logout {
    .defaultText();
    cursor: pointer;

    &:before {
      content: "";
      height: 14px;
      border-right: 1px solid #DBDBDB;
      margin: 0 10px;
    }
  }

  // logo和检索
  &-logoSearch {
    width: 100%;
    background-color: white;

    &__container {
      .container();
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 123px;
      padding-right: 231px;
      margin-bottom: -2px;
    }
  }

  // logo部分
  &-logo {
    display: flex;
    cursor: pointer;
    margin-top: -12px;

    &__img {
      width: 79px;
      height: 59px;
      margin-right: 16px;
    }

    &__text {
      width: 145px;
      height: 51px;
    }
  }

  .seacrch_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;

    .searchWord {
      display: flex;
      font-family: "PingFang SC", serif;
      font-size: 14px;
      line-height: 36px;
      color: #999;

      li {
        margin-left: 5px;
        max-width: 90px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
      li:nth-child(n+3) {
        margin-left: 14px;
      }

      li:first-child {
        margin-left: 15px;
        cursor: default;
      }

      .change {
        padding-left: 20px;
        background: url(~@/assets/index/preferBook__refresh.png) no-repeat left center;
      }
    }

    .historyWords {
      top: 46px;
      width: 480px;
      max-height: 295px;
      z-index: 100;
      background: #fff;
      padding-bottom: 20px;
      box-shadow: 0 0 8px rgba(123, 123, 123, 0.16);
      position: absolute;
      font-family: "PingFang SC", serif;
      font-size: 14px;
      line-height: 32px;
      color: #333;

      li {
        padding: 2px 18px 0 16px;
        display: flex;
        justify-content: space-between;

        &:hover {
          color: #CC0000;
        }

        span {
          cursor: pointer;
        }
        span:first-child {
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 20px;
        }
      }

      li:first-child {
        background: #f5f5f5;
      }
    }
  }

  // 检索部分
  &-search {
    @height: 46px;
    display: flex;
    position: relative;

    // 下拉框
    &_select {
      width: 80px;
      margin-right: -82px;
      margin-top: 2px;
      height: 42px;
      font-family: PingFang SC, sans-serif;

      /deep/ .ant-select-selection,
      /deep/ .ant-select-selection:focus,
      /deep/ .ant-select-selection:active {
        border: 0;
        box-shadow: none;
      }

      /deep/ .ant-select-selection--single {
        height: 42px;
      }

      /deep/ .ant-select-selection__rendered {
        line-height: 40px;
        margin-left: 17px;
        height: 42px;
      }
    }

    // 输入框
    &__input {
      .defaultText();
      width: 484px;
      height: @height;
      border-radius: 8px 0 0 8px;
      border: 2px solid #c00;
      padding: 0 16px 0 84px;
    }

    &Submit {
      display: flex;
      align-items: center;
      width: 94px;
      height: @height;
      background-color: #CC0000;
      border-radius: 0 8px 8px 0;
      cursor: pointer;

      &__icon {
        margin: 0 11px 0 14px;
      }

      &__text {
        .defaultText(white);
      }
    }

  }

  // 检索下拉框
  /deep/ .ant-select-dropdown-menu-item {
    padding-left: 18px;
    font-weight: normal;
    font-family: PingFang SC, sans-serif;
  }

  /deep/ .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
  /deep/ .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background: #f5f5f5;
  }

  // 导航部分
  &-nav {
    width: 100%;
    background-color: white;

    &__container {
      .container()
    }

    &List {
      display: flex;
      > li:nth-child(1) {
        padding: 0 21px 9px 28px;
      }
      > li:nth-child(2) {
        padding: 0 30px 9px 38px;
      }
    }

    &Item {
      min-width: 85px;
      padding: 0 30px 9px 30px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      color: #333333;

      &--active {
        border-bottom: 2px solid #CC0000;
        color: #CC0000;
        font-weight: bold;
      }

      &:hover {
        color: #CC0000;
        font-weight: bold;
      }

      &__text {
        font-size: 18px;
        font-family: PingFang SC, sans-serif;
      }

    }
  }

  // 左侧跳转
  &-rigthflex {
    position: fixed;
    right: 10px;
    top: 50%;
    margin-top: -125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 68px;
    min-height: 250px;
    border-radius: 8px;
    background: #fcfcfc;
    border: 0.5px solid #e2e2e2;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.09);
    z-index: 100;

    li {
      width: 45px;
      height: 62px;
      cursor: pointer;
      border-top: 0.5px solid #e2e2e2;
    }

    li:first-child {
      border: 0;
    }

    .personal_center {
      background: url(../assets/mainHeader/personal_center.png) no-repeat center;
    }

    .phone {
      position: relative;
      background: url(../assets/mainHeader/phone.png) no-repeat center;
      img {
        display: none;
        position: absolute;
        top: -58px;
        left: -220px;
        width: 200px;
        box-shadow: 0 0 13px rgba(0, 0, 0, 0.09);
      }
      &:hover {
        img {
          display: block;
        }
      }
    }
    .WeChat {
      background: url(~@/assets/mainHeader/WeChat.png) no-repeat center;
    }

    .shopping_car {
      border-top: 0.5px solid #e2e2e2 !important;
      background: url(../assets/mainHeader/shopping_car.png) no-repeat center;
    }

    .toping {
      background: url(../assets/mainHeader/toping.png) no-repeat center;
    }

    .wkl-translateIcon {

      background: url(../assets/index/translate.png) no-repeat center;
    }

    /deep/ .ant-badge-count {
      top: 15px;
      right: 5px;
    }
  }
}
</style>
