import mainLayout from '@/layouts/MainLayout.vue'

export default [
  {
    path: '/',
    name: 'wkl',
    component: mainLayout,
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('@/views/Index.vue'),
        meta: {
          title: '首页',
          hideBreadcrumb: true
        }
      },
      {
        path: '/CMSSearch',
        name: 'CMSSearch',
        component: () => import('@/views/Search/CMSSearch'),
        meta: {
          title: '搜索结果',
          hideBreadcrumb: true
        }
      },
      {
        path: '/SearchResult',
        name: 'SearchResult',
        component: () => import('@/views/Search/SearchResult'),
        meta: {
          title: '搜索结果',
          hideBreadcrumb: true
        }
      },
      {
        path: '/search-video',
        name: 'SearchVideo',
        component: () => import('@/views/Search/SearchVideo.vue'),
        meta: {
          title: '搜索结果',
          hideBreadcrumb: true
        }
      },
      {
        path: '/search-audio',
        name: 'SearchAudio',
        component: () => import('@/views/Search/SearchAudio.vue'),
        meta: {
          title: '搜索结果',
          hideBreadcrumb: true
        }
      },
      {
        path: '/PreferGoodBooks',
        name: 'PreferGoodBooks',
        component: () => import('@/views/PreferGoodBooks'),
        meta: {
          title: '优选好书'
        }
      },
      {
        path: '/book-class',
        name: 'bookClass',
        component: () => import('@/views/bookClass'),
        meta: {
          title: '分类'
        }
      },
      {
        path: '/book-class/book-detail',
        name: 'bookClassBookDetail',
        component: () => import('@/views/BookDetail'),
        meta: {
          title: '书籍详情'
        }
      },
      {
        path: '/BookDetail',
        name: 'BookDetail',
        component: () => import('@/views/BookDetail'),
        meta: {
          title: '书籍详情'
        }
      },
      {
        path: '/MyBookshelf',
        name: 'MyBookshelf',
        component: () => import('@/views/MyBookshelf/MyBookshelf'),
        meta: {
          title: '我的书架'
        }
      },
      {
        path: '/MyBookList',
        name: 'MyBookList',
        component: () => import('@/views/MyBookshelf/MyBookList'),
        meta: {
          title: '我的书单'
        }
      },
      {
        path: '/MyBookListDetail',
        name: 'MyBookListDetail',
        component: () => import('@/views/MyBookshelf/MyBookListDetail'),
        meta: {
          title: '我的书单详情'
        }
      },
      {
        path: '/Purchased',
        name: 'Purchased',
        component: () => import('@/views/MyBookshelf/Purchased'),
        meta: {
          title: '已购'
        }
      },
      {
        path: '/purchased-media',
        name: 'PurchasedMedia',
        component: () => import('@/views/purchasedMedia/PurchasedMedia.vue'),
        meta: {
          title: '已购音视频'
        }
      },
      {
        path: '/historical-browsing',
        name: 'HistoricalBrowsing',
        component: () => import('@/views/historicalBrowsing/HistoricalBrowsing.vue'),
        meta: {
          title: '历史浏览'
        }
      },
      {
        path: '/ranking',
        name: 'ranking',
        component: () => import('@/views/ranking'),
        meta: {
          title: '排行榜'
        }
      },
      {
        path: '/video-zone',
        name: 'videoZone',
        component: () => import('@/views/video/VideoZone.vue'),
        meta: {
          title: '视频专区'
        }
      },
      {
        path: '/audio-zone',
        name: 'audioZone',
        component: () => import('@/views/audio/AudioZone.vue'),
        meta: {
          title: '音频专区'
        }
      },
      {
        path: '/audio-detail',
        name: 'audioDetail',
        component: () => import('@/views/audio/AudioDetail.vue'),
        meta: {
          title: '音频详情'
        }
      },
      {
        path: '/book-review-zone',
        name: 'bookReviewZone',
        component: () => import('@/views/bookReviewZone'),
        meta: {
          title: '书评专区'
        }
      },
      {
        path: '/BookListPlaza',
        name: 'BookListPlaza',
        component: () => import('@/views/BookListPlaza'),
        meta: {
          title: '书单广场'
        }
      },
      {
        path: '/ListPlazaDetail',
        name: 'ListPlazaDetail',
        component: () => import('@/views/ListPlazaDetail'),
        meta: {
          title: '书单详情'
        }
      },
      {
        path: '/journal-zone',
        name: 'journalZone',
        component: () => import('@/views/journalZone'),
        meta: {
          title: '期刊专区'
        }
      },
      {
        path: '/personal-center',
        name: 'PersonalCenter',
        component: () => import('@/views/PersonalCenter/PersonalCenter.vue'),
        meta: {
          title: '个人中心'
        }
      },
      // {
      //   path: '/Coupon',
      //   name: 'Coupon',
      //   component: () => import('@/views/PersonalCenter/Coupon'),
      //   meta: {
      //     title: '优惠券'
      //   }
      // },
      // {
      //   path: '/VoucherCollectionCenter',
      //   name: 'VoucherCollectionCenter',
      //   component: () => import('@/views/PersonalCenter/VoucherCollectionCenter'),
      //   meta: {
      //     title: '领券中心'
      //   }
      // },
      {
        path: '/WelfareCenter',
        name: 'WelfareCenter',
        component: () => import('@/views/PersonalCenter/WelfareCenter'),
        meta: {
          title: '福利中心'
        }
      },
      {
        path: '/Package',
        name: 'Package',
        component: () => import('@/views/PersonalCenter/Package'),
        meta: {
          title: '套餐'
        }
      },
      {
        path: '/MyOrder',
        name: 'MyOrder',
        component: () => import('@/views/PersonalCenter/MyOrder'),
        meta: {
          title: '我的订单'
        }
      },
      {
        path: '/OrderReview',
        name: 'OrderReview',
        component: () => import('@/views/PersonalCenter/OrderReview'),
        meta: {
          title: '订单审批'
        }
      },
      {
        path: '/ShoppingCarIndex',
        name: 'ShoppingCarIndex',
        component: () => import('@/views/ShoppingCar/ShoppingCarIndex'),
        meta: {
          title: '购物车'
        }

      },
      {
        path: '/Settlement',
        name: 'Settlement',
        component: () => import('@/views/ShoppingCar/Settlement'),
        meta: {
          title: '结算'
        }
      },
      {
        path: '/media-settlement',
        name: 'MediaSettlement',
        component: () => import('@/views/mediaSettlement/MediaSettlement.vue'),
        meta: {
          title: '结算'
        }
      },
      {
        path: '/OrderSubmission',
        name: 'OrderSubmission',
        component: () => import('@/views/ShoppingCar/OrderSubmission'),
        meta: {
          title: '支付'
        }
      },
      {
        path: '/pay-media-order',
        name: 'PayMediaOrder',
        component: () => import('@/views/mediaSettlement/PayMediaOrder.vue'),
        meta: {
          title: '支付'
        }
      },
      {
        path: '/OrderSuccess',
        name: 'OrderSuccess',
        component: () => import('@/views/ShoppingCar/OrderSuccess'),
        meta: {
          title: '支付成功'
        }
      },
      {
        path: '/media-purchase-result',
        name: 'MediaPurchaseResult',
        component: () => import('@/views/mediaSettlement/MediaPurchaseResult.vue'),
        meta: {
          title: '支付成功'
        }
      },
      {
        path: '/PaymentPassword',
        name: 'PaymentPassword',
        component: () => import('@/views/PersonalCenter/PaymentPassword'),
        meta: {
          title: '修改支付密码'
        }
      },
      // {
      //   path: '/MyVip',
      //   name: 'MyVip',
      //   component: () => import('@/views/PersonalCenter/MyVip'),
      //   meta: {
      //     title: '我的会员'
      //   }
      // },
      {
        path: '/OrdersDetails',
        name: 'OrdersDetails',
        component: () => import('@/views/PersonalCenter/OrdersDetails'),
        meta: {
          title: '订单详情'
        }
      },
      {
        path: '/InvoiceDetails',
        name: 'InvoiceDetails',
        component: () => import('@/views/InvoiceDetails'),
        meta: {
          title: '发票详情'
        }
      },
      {
        path: '/video-detail',
        name: 'videoDetail',
        component: () => import('@/views/video/VideoDetail.vue'),
        meta: {
          title: '视频详情'
        }
      },
      {
        path: '/book-review-detail',
        name: 'bookReviewDetail',
        component: () => import('@/views/CMSContent'),
        meta: {
          title: '书评详情'
        }
      },
      {
        path: '/messageBoard',
        name: 'messageBoard',
        component: () => import('@/views/CMSContent'),
        meta: {
          title: '留言板'
        }
      },
      {
        path: '/journalDetail',
        name: 'journalDetail',
        component: () => import('@/views/journalDetail'),
        meta: {
          title: '期刊详情'
        }
      },
      {
        path: '/approve-order-details',
        name: 'ApproveOrderDetails',
        component: () => import('@/views/PersonalCenter/ApproveOrderDetails.vue'),
        meta: {
          title: '审批订单详情'
        }
      }
    ]
  },
  {
    path: '/reader',
    name: 'reader',
    component: () => import('@/views/reader/ReaderIndex.vue'),
    meta: {
      title: '阅读'
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('@/views/Register'),
    meta: {
      title: '注册'
    }
  },
  {
    path: '/LogoutTxt',
    name: 'LogoutTxt',
    component: () => import('@/views/Logout/LogoutTxt'),
    meta: {
      title: '账户注销'
    }
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: () => import('@/views/Logout/Logout'),
    meta: {
      title: '确认注销用户'
    }
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/views/resetPassword'),
    meta: {
      title: '忘记密码'
    }
  }

]
