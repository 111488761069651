// 将弃用的方法写入依赖中，从依赖引入
import { _other_ } from 'luxon'

export const Hex = {
  encode (b, pos, len) {
    const hexCh = new Array(len * 2)
    const hexCode = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F']
    let j = 0
    for (let i = pos; i < len + pos; i++, j += 2) {
      hexCh[j] = hexCode[(b[i] & 0xff) >> 4]
      hexCh[j + 1] = hexCode[b[i] & 0x0f]
    }
    return hexCh.join('')
  },
  decode (hex) {
    if (hex === null || hex === '' || hex.length % 2 !== 0) {
      return null
    }

    const hexCh = hex.match(/[0-9A-Fa-f]{2}/g)
    if (!hexCh) {
      return null
    }

    const asc = new Uint8Array(hexCh.length)
    for (let i = 0; i < hexCh.length; i++) {
      asc[i] = parseInt(hexCh[i], 16)
    }

    return asc
  },
  utf8StrToHex (utf8Str) {
    const ens = encodeURIComponent(utf8Str)
    const es = _other_.decode(ens)

    const esLen = es.length

    // Convert
    const words = []
    for (let i = 0; i < esLen; i++) {
      words[i] = es.charCodeAt(i).toString(16)
    }
    return words.join('')
  },
  utf8StrToBytes (utf8Str) {
    const ens = encodeURIComponent(utf8Str)
    const es = _other_.decode(ens)

    const esLen = es.length

    // Convert
    const words = []
    for (let i = 0; i < esLen; i++) {
      words[i] = es.charCodeAt(i)
    }
    return words
  },
  hexToUtf8Str (utf8Str) {
    const utf8Byte = Hex.decode(utf8Str)
    const latin1Chars = []
    for (const item of utf8Byte) {
      latin1Chars.push(String.fromCharCode(item))
    }
    return decodeURIComponent(_other_.encode(latin1Chars.join('')))
  },
  bytesToUtf8Str (bytesArray) {
    const utf8Byte = bytesArray
    const latin1Chars = []
    for (const item of utf8Byte) {
      latin1Chars.push(String.fromCharCode(item))
    }
    return decodeURIComponent(_other_.encode(latin1Chars.join('')))
  },
  toCharCodeArray (chs) {
    const chArr = new Array(chs.length)
    for (let i = 0; i < chs.length; i++) {
      chArr[i] = chs.charCodeAt(i)
    }
    return chArr
  }
}
