import VueCookies from 'vue-cookies'

export default {
  install (Vue) {
    Vue.prototype.$util = {
      /**
       * 获取是否登录
       * @returns {boolean}
       */
      isLogin () {
        return !!VueCookies.get('access-token')
      }

    }
  }
}
