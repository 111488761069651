import { getDateString } from '@/utils/util'

const reader = {
  state: {
    // 图书类型
    bookType: '',
    // 请求参数
    query: {
      /** 基本 */
      bookId: '',
      createTime: '',
      jumpTo: undefined, // 目录跳转参数
      /** 期刊 */
      isJournal: undefined,
      topicId: undefined,
      /** 其他 */
      neededType: undefined, // 需要的类型
      /** 继续阅读 */
      isContinue: undefined,
      fileIndex: undefined,
      tempAnchor: undefined,
      pageNum: undefined
    },
    // 基本信息
    base: {
      iid: '', // 图书ID
      createTime: '',
      bookName: '', // 图书名称
      memoryType: 0, // 存储类型 1 epub, 2 pdf, 3 epub + pdf
      bookType: '', // 图书类型 1 epub, 2 pdf
      chapterCount: '', // 总章节数
      trialChapter: '', // 可可试读章节数
      pageCount: '', // 总页数
      trialPage: '', // 可试读页数
      free: false, // 是否免费
      trial: false, // 是否支持试读
      own: false, // 是否已购买
      imgUrl: '', // 封面图片
      introduction: '', // 简介
      fileWords: 0, // epub总字数
      totalIndex: 0, // epub总分片数
      /** 期刊相关 */
      phase: undefined,
      year: undefined,
      /** 其他 */
      author: '',
      publishHouse: '',
      ownRight: undefined,
      rightStartTime: undefined,
      rightEndTime: undefined,
      epubAnalysisState: '', // epub解析状态
      pdfAnalysisState: '' // pdf解析状态
    },
    // 阅读器配置
    readerConfig: {
      fromOtherReader: false, // 是否来自其他阅读器
      pdfPage: 1, // pdf阅读记录
      epubFileIndex: 1, // epub当前切片数
      epubHref: '', // epub当前href
      epubCurrentAnchor: '', // epub当前阅读位置锚点
      fontSize: 22, // 字体大小
      space: 2, // 字体间距
      theme: 'day'
    },
    // epub目录列表
    epubMenuList: [],
    // pdf目录列表
    pdfMenuList: [],
    // epub内容列表
    epubContentList: [],
    // pdf内容列表
    pdfContentList: [],
    // 屏幕中心点坐标
    centerPoint: {
      x: 0,
      y: 0
    },
    // 滚动事件名称
    mousewheel: 'mousewheel'
  },
  mutations: {
    /**
     * 设置滚动事件名称
     */
    setMousewheel (state) {
      if (navigator.userAgent.indexOf('Firefox') > -1) {
        // 针对火狐设置滚动事件名称
        state.mousewheel = 'DOMMouseScroll'
      }
    },
    /**
     * 获取请求参数
     */
    getQuery (state, payload) {
      const { data } = payload
      for (const key in state.query) {
        if (key === 'createTime') {
          state.query[key] = getDateString(parseInt(data[key]), 'yyyy-mm-dd hh:mm:ss')
          continue
        }

        if (key === 'isJournal') {
          state.query[key] = data[key] === '1'
          continue
        }

        state.query[key] = data[key]
      }
    },
    /**
     * 获取中心位置的坐标
     */
    getCenterPoint (state) {
      state.centerPoint.x = document.documentElement.clientWidth / 2
      state.centerPoint.y = document.documentElement.clientHeight / 2
    },
    /**
     * 设置基本信息
     */
    setBaseInfo (state, payload) {
      const { type, data } = payload
      for (const key in state.base) {
        if (key === 'free') {
          state.base[key] = data[key] === '1'
          continue
        }
        if (key === 'trial') {
          state.base[key] = data[key] === '1'
          continue
        }
        if (key === 'own') {
          state.base[key] = data[key] === '1'
          continue
        }
        // 对于期刊的特殊处理
        if (type === 'journal') {
          if (key === 'iid') {
            state.base[key] = state.query.bookId
            continue
          }
          if (key === 'createTime') {
            state.base[key] = state.query.createTime
            continue
          }
        }

        state.base[key] = data[key]
      }
    },
    /**
     * 更改图书类型
     */
    changeBookType (state, payload) {
      state.bookType = payload
    },
    /**
     * 设置目录相关的数据
     */
    setMenuData (state, payload) {
      const { data } = payload
      if (state.bookType === 'pdf') {
        state.pdfMenuList = data.pdfMenuVO.pdfMenuDTOList
      } else if (state.bookType === 'epub') {
        state.epubMenuList = data.epubMenuVO.epubMenuList
        state.base.fileWords = data.epubMenuVO.fileWords
        state.base.totalIndex = data.epubMenuVO.totalIndex
        state.base.chapterCount = data.epubMenuVO.pageTotalSize
      }
    },
    /**
     * 存储EPUB内容列表
     */
    storageEPUBContentList (state, payload) {
      const { data, index } = payload
      if (state.epubContentList.length === 0) {
        state.epubContentList = data
      } else {
        state.epubContentList[index] = data[index]
      }
    },
    /**
     * 更改字体大小
     */
    changeFontSize (state, payload) {
      const { fontSize } = payload
      state.readerConfig.fontSize = fontSize
    },
    /**
     * 更改间距大小
     */
    changeSpace (state, payload) {
      const { space } = payload
      state.readerConfig.space = space
    },
    /**
     * 更改主题
     */
    changeTheme (state, payload) {
      const { theme } = payload
      state.readerConfig.theme = theme
    },
    /**
     * 更改PDF阅读器当前页数
     */
    changePDFPage (state, payload) {
      const { page } = payload
      state.readerConfig.pdfPage = page
    },
    /**
     * 设置EPUB书签相关的配置
     */
    setEPUBBookMarkConfig (state, payload) {
      const { fileIndex, href } = payload
      state.readerConfig.epubFileIndex = fileIndex
      state.readerConfig.epubHref = href
    },
    /**
     * 移除继续阅读的标识
     */
    removeIsContinue (state) {
      state.query.isContinue = undefined
    },
    /**
     * 移除目录跳转参数
     */
    removeJumpTo (state) {
      state.query.jumpTo = undefined
    },
    /**
     * 设置EPUB的当前阅读进度锚点
     */
    setEPUBCurrentAnchor (state, payload) {
      const { anchor } = payload
      state.readerConfig.epubCurrentAnchor = anchor
    },
    /**
     * 缓存内容列表
     */
    storageContentList (state, payload) {
      const { type, data } = payload
      if (type === 'epub') {
        state.epubContentList = data
      } else if (type === 'pdf') {
        state.pdfContentList = data
      }
    },
    /**
     * 切换版本
     */
    switchVersion (state, payload) {
      const { version } = payload
      state.bookType = version
    },
    /**
     * 设置版本切换标识
     */
    setFromOtherReader (state, payload) {
      const { value } = payload
      state.readerConfig.fromOtherReader = value
    }

  },
  actions: {}
}

export default reader
