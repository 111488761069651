// 静态路由
import constantRouterMap from './router.constant'

let routerSchema = []

// 各模块路由整合
const context = require.context('@/config/modules', true, /\/.*\.js$/)
context.keys().forEach((key) => {
  const moduleRouterSchema = context(key).default
  if (moduleRouterSchema) {
    if (moduleRouterSchema instanceof Array) {
      routerSchema = [...routerSchema, ...moduleRouterSchema]
    } else {
      routerSchema.push(moduleRouterSchema)
    }
  }
})
routerSchema = [...constantRouterMap, ...routerSchema]

export default routerSchema
