import { requestInterface } from '@/api/wklAPI'
import VueCookies from 'vue-cookies'

/**
 * 获取票据
 * @return {Promise<unknown>}
 */
export function getTicket () {
  return new Promise(resolve => {
    requestInterface({
      appId: 'jis',
      interfaceId: 'jisgenerateTicket',
      params: {
        appMark: 'jis',
        params: {
          token: VueCookies.get('access-token'),
          proxyapp: '第三方应用唯一标识'
        }
      }
    }).then(res => {
      if (res.retcode !== '000000' || !res.data) {
        resolve(undefined)
        return
      }
      const data = JSON.parse(res.data)
      resolve(data.ticket)
    })
  })
}
