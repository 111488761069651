<script setup>
import { onMounted, provide, ref } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router/composables'
import { get, set } from '@vueuse/core'
import { useNamespace } from '@/hook/useNamespace'
import MainHeader from '@/layouts/MainHeader.vue'
import MainFooter from '@/layouts/MainFooter.vue'
import SimpleAudioPlayer from '@components/purchasedMedia/SimpleAudioPlayer.vue'

const route = useRoute()
const ns = useNamespace('mainLayout')

// 首页路径
const indexPath = '/'

// 是否隐藏面包屑
const hideBreadcrumb = ref(false)
// 面包屑列表
const breadcrumbList = ref([])
// 当前页面是否为福利中心
const isWelfareCenter = ref(false)
// 头部组件刷新标识
const headerKey = ref(0)

/**
 * 路由对象
 * @typedef {Object} RouteObj
 * @property {string} name - 名称
 * @property {string} path - 路径
 * @property {Object} meta - 配置
 * @property {Object} query - 传参
 */

/**
 * 处理路由
 * @param {RouteObj} to - 路由对象
 */
function handleRoute(to) {
  set(hideBreadcrumb, !!to.meta.hideBreadcrumb)
  set(breadcrumbList, [
    {
      name: '首页',
      path: indexPath
    }
  ])

  // 使用福利中心面包屑样式的路由名称
  const isWelfareCenterName = [
    'WelfareCenter',
    'OrderReview',
    'ApproveOrderDetails',
    'ReadingHistory',
    'MyOrder',
    'OrdersDetails',
    'InvoiceDetails',
    'PersonalCenter'
  ]

  set(isWelfareCenter, isWelfareCenterName.includes(to.name))

  // 对从分类页面进入的书籍详情页做处理
  if (to.name === 'bookClassBookDetail') {
    get(breadcrumbList).push({
      name: '分类',
      path: `/book-class?${to.query.bookClassQuery}`
    })
  }

  // 对从个人中心页面进入的福利中心做处理
  if (to.name === 'WelfareCenter') {
    get(breadcrumbList).push({
      name: '个人中心',
      path: '/personal-center'
    })
  }

  if (to.path !== indexPath) {
    get(breadcrumbList).push({
      name: to.meta.title,
      path: handleUrl(to),
      isLast: true
    })
  }

  const setTitleList = ['阅读', '视频详情', '音频详情', '书评详情']

  if (setTitleList.includes(to.meta.title)) {
    // 判断是否有标题
    if (typeof to.query.name !== 'undefined') {
      document.title = to.query.name
    } else {
      document.title = to.meta.title
    }
  } else {
    document.title = to.meta.title
  }
  headerKey.value++
}

/**
 * 处理链接
 * @param {RouteObj} to - 路由对象
 */
function handleUrl (to) {
  let url = to.path
  // 存在参数
  const keyList = Object.keys(to.query)
  if (keyList.length !== 0) {
    url += '?'
    for (const key in to.query) {
      url += `${key}=${to.query[key]}`
      if (key !== keyList[keyList.length - 1]) {
        url += '&'
      }
    }
  }

  return url
}

onBeforeRouteUpdate((to, from, next) => {
  handleRoute(to)
  next()
})

onMounted(() => {
  handleRoute(route)
})

// 音频播放器配置
const audioPlayerConfig = ref({
  visible: false,
  data: {}
})

/**
 * 显示音频播放器
 * @param {Object} data - 播放器需要的数据
 */
function showAudioPlayer(data) {
  get(audioPlayerConfig).data = data
  get(audioPlayerConfig).visible = false
  setTimeout(() => {
    get(audioPlayerConfig).visible = true
  }, 0)
}

/**
 * 关闭音频播放器
 */
function closeAudioPlayer() {
  get(audioPlayerConfig).visible = false
}

provide('mainLayout', {
  showAudioPlayer
})
</script>
<!-- 主要布局 -->
<template>
  <a-layout :class="ns.b()">
    <main-header
      :current-router="breadcrumbList[breadcrumbList.length - 1]"
      :key="headerKey" />
    <!-- 面包屑 -->
    <div
      :class="{
        [ns.b('breadcrumb')]: true,
        [ns.bm('breadcrumb', 'welfareCenter')]: isWelfareCenter
      }"
      v-if="!hideBreadcrumb">
      <div :class="ns.be('breadcrumb', 'container')">
        <ul :class="ns.be('breadcrumb', 'list')">
          <li
            :class="ns.be('breadcrumb', 'item')"
            v-for="(item, index) in breadcrumbList"
            :key="index">
            <router-link
              :class="ns.be('breadcrumb', 'link')"
              :to="item.path">{{ item.name }}</router-link>
            <div
              :class="ns.be('breadcrumb', 'separator')"
              v-if="index !== breadcrumbList.length - 1">&gt;</div>
          </li>
        </ul>
      </div>
    </div>
    <router-view />
    <main-footer />
    <!-- 简易音频播放器 -->
    <simple-audio-player
      v-if="audioPlayerConfig.visible"
      :data="audioPlayerConfig.data"
      @close="closeAudioPlayer"/>
  </a-layout>
</template>

<style lang="less" scoped>
@import "@/style/commonStyle.less";

.wa-mainLayout-breadcrumb {
  width: 100%;
  background: #fff url(~@/assets/mainHeader/top_shadow.png) repeat-x top left;

  &__container {
    .container()
  }

  &__list {
    display: flex;
    padding: 20px 0;
  }

  &__item {
    display: flex;
  }

  &__link {
    .defaultText(#999999);
  }

  &__separator {
    .defaultText(#999999);
    user-select: none;
    margin: 0 4px;
  }

  &--welfareCenter {
    background: #F6F6F6;

    .wa-mainLayout-breadcrumb {
      &__list {
        padding: 10px 0;
      }
    }
  }
}
</style>
