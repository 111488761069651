import { render, staticRenderFns } from "./MainFooter.vue?vue&type=template&id=7cc75284&scoped=true"
import script from "./MainFooter.vue?vue&type=script&lang=js"
export * from "./MainFooter.vue?vue&type=script&lang=js"
import style0 from "./MainFooter.vue?vue&type=style&index=0&id=7cc75284&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_css-loader@6.11.0_webpack@5.92.1__lodash@4.17.21__mzqvytlaul3nppswafhhvjwwbu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cc75284",
  null
  
)

export default component.exports