import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import reader from './modules/reader'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    reader
  },
  state: {
    requestCancels: [] // 当前路由的所有Axios请求取消函数
  },
  mutations: {

  },
  actions: {

  },
  getters
})
